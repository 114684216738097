.custom-button {
    border-radius: 10px;
    background-color: #02335B;
    color: #fff;
    padding: 10px;
    border: none;
}

.custom-row .custom-col {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
}

.custom-row .custom-col:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0.3px;
    height: 100%;
    background-color: grey;
}

@media screen and (max-width: 767px) {
    .custom-button {
        width: 100%;
        margin-top: 10px;
    }

    #car-avatar {
        margin-top: 10px;
    }

    .seats-left-responsive {
        text-align: left !important; /* Override text-end */
    }

    .custom-row .custom-col {
        padding-left: 0;
        padding-right: 0;
    }

    .custom-row .custom-col:not(:last-child)::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.3px;
        background-color: grey;
    }

    /* Remove the line above the first element */
    .custom-row .custom-col:first-child::after {
        display: none;
    }
}