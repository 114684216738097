.custom-container {
    width: 80%;
}

.custom-hr {
    margin-top: 30px;
    margin-bottom: 30px;
    height: 0.5px;
    background-color: grey;
    border: 'none';
}

.join-button {
    border-radius: 5px;
    background-color: #FD7E14;
    color: #fff;
    padding: 10px;
    border: none;
    width: 100%;
}

.join-button:hover {
    background-color: #f8a058;
    border: none;
}

/* Checkbox checked state */
.custom-checkbox:checked {
    border-color: #c603fc;
    background-color: white;
}

/* Custom checkmark */
.custom-checkbox:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    background-color: 'white';
    transform: translate(-50%, -50%);
    border-radius: 2px;
}

.custom-checkbox:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 4px;
    width: 4px;
    height: 6px;
    border: solid #c603fc;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.form-check-label {
    margin-right: 10px;
}

.custom-input {
    height: 40px;
    background-color: #f0f0f0 !important;
    border: none !important;
}

.custom-input::placeholder {
    font-size: 14px;
}

.custom-form-check {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.custom-checkbox {
    margin-right: 10px;
    /* Adjust as needed for spacing */
}

.checkbox-label-filters {
    margin: 0;
    /* Remove default margin */
}