.message-text {
    color: #02335B;
}

.button-success {
    border-radius: 30px;
    width: 30%;
    margin-top: 5px;
    margin-bottom: 5px;
    color: white;
    background-color: #399CFF;
    padding: 10px;
    border: none;
}

.button-failure {
    border-radius: 30px;
    width: 30%;
    margin-top: 5px;
    margin-bottom: 5px;
    color: white;
    background-color: #FF3D22;
    padding: 10px;
    border: none;
}

.green-icon {
    color: #32a852;
    margin-right: 8px;
}

.modal-p {
    margin: 20px
}

.modal-footer-container {
    display: flex;
    justify-content: center;
}