.menu-button {
    border-radius: 10px;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #CED7E1;
    color: #02335B;
    border: none;
    padding: 15px;
}

.menu-button:hover {
    background-color: #878787;
    color: white;
}

.menu-button.active {
    background-color: #878787;
    color: white;
}

.menu-delete-button {
    border-radius: 10px;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #FF3D22;
    color: white;
    border: none;
    padding: 15px;
}

.menu-delete-button.active {
    border-radius: 10px;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
    color: white;
    background-color: #b12310;
    border: none;
}

/**
 * Profile settings input forms
 */

.custom-profile-settings-input-label {
    color: #02335B;
    font-size: 14px;
}

.custom-profile-settings-input {
    background-color: #ECF4FC !important; 
    border: 1px #C5C9CC solid !important;
}

.custom-profile-settings-submit-button {
    color: white;
    background-color: #399CFF;
    border: none;
    border-radius: 10px;
    padding: 10px;
    width: 50%;
}

.custom-profile-settings-submit-button:hover {
    background-color: #1e8af5;
}

.account-delete-button {
    border-radius: 30px;
    width: 30%;
    margin-top: 5px;
    margin-bottom: 5px;
    color: white;
    background-color: #FF3D22;
    padding: 10px;
    border: none;
}

/* Car settings */
.car-settings-info-text {
    color: #02335B;
    text-align: left;
    font-size: 16px;
}

.car-settings-info-label {
    color: #a5a6a6;
    text-align: left;
    font-size: 14px;
    margin-bottom: 5px;
}