/* Chat rooms */

.join-room-button {
    border-radius: 30px;
    background-color: #dec4f2;
    color: #9614FD;
    border: none;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    width: 50%;
}

.join-room-button:hover {
    border-radius: 30px;
    background-color: #9614FD;
    color: white;
    border: none;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    width: 50%;
}

.joined-label {
    border-radius: 30px;
    background-color: #cfe5fc;
    color: #007BFF;
    border: none;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    width: 50%;
    text-align: center;
}

.hr-rooms {
    border: 0.5px solid #02335B;
    margin: 10px 0;
}

.chat-rooms-availability-message {
    color: '#02335B';
    font-size: 24px;
}

/* Chat messages */
.send-message-button {
    background-color: #101D27;
    border-radius: 10px;
    border: none;
    color: white;
    padding: 5px;
}

.custom-message-input {
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
}

.user-message {
    background-color: rgb(68, 68, 176);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px;
}

.other-message {
    background-color: #F3F3F3;
    color: #02335B;
    border: none;
    border-radius: 10px;
    padding: 5px;
}

.sender-name {
    color: lightgrey;
    font-size: 0.8rem;
    margin-top: -5px;
    margin-bottom: 5px;
}