body {
  margin: 0;
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
label {
  font-family: "Noto Sans", sans-serif
}

@media (max-width: 767px) {
  .date-control {
    width: 100% !important;
    /* Adjust the width as needed */
  }
}

.react-datepicker-wrapper {
  display: block !important;
  /* Set the desired display property */
  border-radius: 6px;
  line-height: 33px;
}

.clickable-circle {
  width: 60px;
  height: 60px;
  background-color: lightgrey;
  cursor: pointer;
  border: 2px solid transparent;
  /* Transparent border by default */
  transition: border-color 0.3s ease;
  /* Smooth transition effect */
}

.clickable-circle:hover {
  border-color: orange;
  /* Orange border when hovering */
}

.scrollable-comments {
  max-height: 200px;
  overflow-y: auto;
}

.sender-text {
  font-size: 12px;
}

.comment-card {
  background-color: lightgray;
  padding: 5px;
  border-radius: 0.5rem;
  margin-bottom: 10px;
}

.custom-btn {
  background-color: #FF7518 !important;
  color: #fff !important;
  border: none !important;
  font-size: 1rem !important;
  padding: 10px 20px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

.custom-btn:hover {
  background-color: #e36600 !important;
}

.picker {
  background-color: #f0f0f0 !important;
  border: none !important;
}

.secondary-logo {
  width: 250px;
  height: 40px;
}

.link-text-button-active {
  color: #17242F;
  border: none;
  font-size: 18px;
  background-color: white;
  margin-right: 40px;
  border-bottom: 4px #17242F solid;
}

.link-text-button-inactive {
  color: #A7A7A7;
  border: none;
  font-size: 18px;
  background-color: white;
  margin-right: 40px;
}

/* trip search date selection */
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 80%;
}

.custom-navbar {
  top: 0;
  z-index: 100;
  width: 70%;
  margin: 0 auto;
}

.overlay-text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.overlay-text-bottom-left {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

/* remove the responsive menu icon border */
.navbar-toggler:focus {
  /*Below line will remove the border from the icon
  I have used bootstrap icon here */
  /* This will only remove the shadow effect */
  /* Border is still there */
  /* if you don't want any border then set the property equals to zero */
  border: 0 !important;
  box-shadow: none !important;

  /* This will remove the rounded corner(radious-border)
  and give square border */
  /* border-radius: 0 !important; */
}

.navbar-toggler {
  /* This will completely remove border from the icon */
  /* If you need border for the icon then comment this code */
  border: 0 !important;

  /* if you want to check the border please uncomment below code */
  /* border-color: yellow !important; */
}

@media screen and (max-width: 767px) {
  #root .navbar {
    width: 90%;
  }

  .main-logo {
    width: 150px;
    height: 20px;
  }

  .custom-btn {
    margin-top: 20px;
  }
}