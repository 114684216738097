.custom-button {
    border-radius: 10px;
    background-color: #02335B;
    color: #fff;
    padding: 10px;
}

/* Hide default checkbox */
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    border: 2px solid grey;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: relative;
    margin-left: 10px;
    margin-top: -4px;
}

/* Checkbox checked state */
.custom-checkbox:checked {
    border-color: #c603fc;
    background-color: white;
}

/* Custom checkmark */
.custom-checkbox:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    background-color: 'white';
    transform: translate(-50%, -50%);
    border-radius: 2px;
}

.custom-checkbox:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 4px;
    width: 4px;
    height: 6px;
    border: solid #c603fc;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.form-check-label {
    margin-right: 10px;
}