.placeholder-light-grey::placeholder {
    color: #C8C8C8;
}

.intro {
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 70%;
    margin: 0 auto;
}

.benefits {
    background-color: #F5F5F5;
    padding: 50px 50px;
    margin: 0 auto;
}

.benefits .container {
    width: 70%;
}

/* same as .intro */
.howitworks {
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 70%;
    margin: 0 auto;
}

.howitworks-image {
    border-radius: 30px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonials {
    background-color: #E1FFF7;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0 auto;
}

.contact .container {
    width: 70%;
}

.faq .container {
    width: 70%;
}

.faq-join-image {
    height: auto; /* Default height */
}

.faq-join-ride-button {
    color: white;
    background-color: #FD7E14;
    border-radius: 30px;padding: 15px 40px 15px 40px;
    border: none;
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .intro {
        width: 90%;
    }

    .benefits {
        padding-left: 0;
        padding-right: 0;
    }

    .benefits .container {
        width: 90%;
    }

    .benefits .text-center {
        padding-bottom: 30px;
    }

    .howitworks {
        width: 90%;
    }

    .howitworks-image {
        margin-bottom: 30px;
    }

    .testimonials-cards {
        width: 90%;
    }

    .testimonials .text-center {
        padding-bottom: 30px;
    }

    .contact .container {
        width: 90%;
    }

    .faq .container {
        width: 90%;
    }

    .faq-join-image {
        height: 200px; /* Increased height for mobile */
    }

    .faq-join-ride-button {
        padding: 10px 10px;
    }
}