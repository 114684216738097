/* Add these styles to your CSS */
.loading-overlay {
    position: relative; /* Set to relative to contain absolute elements */
  }
  
  .loading-big-spinner {
    position: absolute; /* Position the spinner absolutely within the container */
    top: 50%; /* Vertically center the spinner */
    left: 50%; /* Horizontally center the spinner */
    transform: translate(-50%, -50%); /* Center the spinner precisely */
    border: 4px solid rgba(255, 255, 255, 0.3); /* Lighter border */
    border-top: 4px solid #3498db; /* Blue border on top */
    border-radius: 50%;
    width: 50px; /* Adjust the size as needed */
    height: 50px; /* Adjust the size as needed */
    animation: spin 2s linear infinite; /* Add a spinning animation */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  