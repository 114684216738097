.form-label-custom {
    font-size: 14px;
    font-weight: bold;
    color: #262E3F;
    display: block;
    text-align: left;
}

.checkboxes-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.custom-checkbox-container .form-check-label {
    color: #262E3F;
    font-weight: bold;
    font-size: 14px;
}

/* .custom-datepicker {
    background: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
} */

.custom-input,
.custom-select .select__control {
    background: #ffffff;
    /* White background */
    border: 1px solid #ccc;
    /* Border color */
    border-radius: 10px;
    /* Rounded corners */
    width: 100%;
    /* Full width */
    box-sizing: border-box;
    /* Include padding and border in the element's total width */
}

.custom-checkbox-container {
    background-color: #DEE0E4;
    border: 1px solid #ccc;
    border-radius: 30px;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-right: 55px;
}

/* Hide default checkbox */
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    border: 2px solid lightgray;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: relative;
    margin-left: 10px;
    margin-top: -4px;
}

/* Checkbox checked state */
.custom-checkbox:checked {
    border-color: #c603fc;
    background-color: white;
}

/* Custom checkmark */
.custom-checkbox:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 2px;
}

.custom-checkbox:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 4px;
    width: 4px;
    height: 6px;
    border: solid #c603fc;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}