.container-fluid {
    padding: 0px;
  }

.image-column img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.custom-form {
    width: 80%;
}

.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.input-label {
    font-size: 12px;
    margin-bottom: 5px;
}

.custom-input {
    height: 40px;
    background-color: #f0f0f0 !important;
    border: none !important;
}

.custom-input::placeholder {
    font-size: 10px;
}

.forgotten-password {
    text-decoration: none;
    color: #007AFF;
    cursor: pointer;
    font-size: 14px;
    text-align: end;
}

.sign-button {
    border-radius: 5px;
    background-color: #FD7E14;
    color: #fff;
    padding: 10px;
    border: none;
    width: 100%;
}

.sign-button:hover {
    background-color: #f8a058;
    border: none;
}

.google-sign-button {
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    background-color: white;
    color: black;
    padding: 10px;
    border: 0.5px solid rgb(199, 199, 199);
    width: 100%;
    text-decoration: none;
}

.google-sign-button:hover {
    background-color: #dcdcdc;
    border: none;
}

.apple-sign-button {
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    background-color: black;
    color: white;
    padding: 10px;
    border: 0.5px solid rgb(199, 199, 199);
    width: 100%;
    text-decoration: none;
}

.apple-sign-button:hover {
    background-color: rgb(42, 42, 42);
    border: none;
}


.social-icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.social-button:hover {
    background-color: #dcdcdc;
    border: none;
}

@media screen and (max-width: 767px) {
    .custom-form {
      width: 95%;
      margin-top: 20px;
    }
  }