.vertical-line {
    border-left: 1px solid #ced4da;
    height: 100%; /* Make the line fill the height of the parent */
    margin-left: 15px;
    margin-right: 15px;
}

.section-label {
    color: #02335B;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
}

.profile-info-element-text {
    color: #4D6273;
    text-align: left;
    margin-left: 35px; 
}

.profile-info-element-label {
    text-align: left;
    color: #02335B;
    font-weight: bold;
    font-size: 18px;
    margin: 0;
}

.profile-settings-button {
    background-color: #02335B;
    color: white;
    border-radius: 10px;
    padding: 10px;
    border: none;
    width: 90%;
    margin-top: 30px;
}

.car-info-text {
    color: #02335B;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
}

.profile-settings-button:hover {
    background-color: #26455e;
}

.reviews-button-group {
    text-align: left;
}

.review-item {
    text-align: left;
}