.approve-button {
    border-radius: 10px;
    width: 30%;
    margin-top: 5px;
    margin-bottom: 5px;
    color: white;
    background-color: #007BFF;
    padding: 10px;
    border: none;
}

.approve-button:hover {
    background-color: rgb(105, 177, 249);
}

.reject-button {
    border-radius: 10px;
    width: 30%;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #9E9E9E;
    background-color: #EEEEEE;
    padding: 10px;
    border: none;
}

.reject-button:hover {
    color: white;
    background-color: rgb(182, 180, 180);
}

.cancel-trip-button {
    border-radius: 10px;
    width: 30%;
    margin-top: 5px;
    margin-bottom: 5px;
    color: white;
    background-color: #FF3D22;
    padding: 10px;
    border: none;
}

.cancel-trip-button:hover {
    background-color: #b12310;
}

.notifications-count-badge {
    color: white;
    font-size: 16px;
    margin-left: 30px;
    background-color: #FF3D22;
    border-radius: 12px;
    padding: 2px 8px;
}

.mark-as-read-button {
    font-size: 14px;
    border-radius: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: white;
    background-color: #007BFF;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
}

.mark-as-read-button:hover {
    background-color: rgb(105, 177, 249);
}